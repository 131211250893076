.typingWindow {
  box-sizing: border-box;
  border: 5px solid var(--black);
  max-width: 120ch;
  padding: 0;
  box-shadow: 10px 10px 2px rgba(0, 0, 0, 0.1);
}

.typingInputContainer,
.statsContainer {
  padding: 2rem 0.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.statsContainer {
  /* height: calc(2rem + 2rem + 4 * (1.2rem + 1rem)); */
  /* same as .textOverflow */
}

@media (min-width: 400px) {
  .typingInputContainer,
  .statsContainer {
    padding: 2rem;
  }
}

.typingInput {
  border: none;
  outline: none;
  opacity: 0;
  margin: 0;
  padding: 0;
  background-color: var(--white);
  cursor: default;
  resize: none;
  z-index: 98;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
}

.unfocused {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  text-shadow: 0px 0px 5px var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.unfocusedText {
  padding: 1rem;
}

button {
  margin: 1rem;
}

.controlBtn {
  margin: 0 1rem 3rem;
  opacity: 0;
  transition: all 0.25s linear 0s;
}

.controlBtn.shown {
  opacity: 100%;
  transition: all 0.25s linear 0s;
}
