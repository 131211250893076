.word {
  line-height: 1.2rem;
  padding: 1rem 0.65rem;
  display: inline-block;
  scroll-margin-bottom: 3.2rem;
}

.letter {
  font-family: monospace;
  font-size: 1.15rem;
  color: lightgrey;
}

.letter:last-child {
  border-right: 3px solid var(--white);
}

.letter.active {
  background-color: var(--black);
  color: var(--white);
  -webkit-animation: blinkChar 1.25s steps(2, end) infinite;
  -moz-animation: blinkChar 1.25s steps(2, end) infinite;
  -o-animation: blinkChar 1.25s steps(2, end) infinite;
  animation: blinkChar 1.25s steps(2, end) infinite;
}

@keyframes blinkChar {
  0%,
  49% {
    background-color: var(--black);
    color: var(--white);
  }
  50%,
  100% {
    background-color: var(--white);
    color: lightgrey;
  }
}

.letter.active.correct:last-child,
.letter.active.incorrect:last-child {
  background-color: transparent;
  color: var(--black);
  color: inherit;
  border-right: 3px solid black;
  -webkit-animation: blinkCursor 1.25s steps(2, end) infinite;
  -moz-animation: blinkCursor 1.25s steps(2, end) infinite;
  -o-animation: blinkCursor 1.25s steps(2, end) infinite;
  animation: blinkCursor 1.25s steps(2, end) infinite;
}
.letter.active.incorrect:last-child {
  color: var(--incorrect);
}

@keyframes blinkCursor {
  0%,
  49% {
    border-color: var(--black);
  }
  50%,
  100% {
    border-color: var(--white);
  }
}

.letter.correct {
  color: var(--correct);
}

.letter.incorrect {
  color: var(--incorrect);
}

.letter.incorrect.space {
  box-sizing: border-box;
  border-bottom: 1px solid var(--incorrect);
}
