.header {
  box-sizing: border-box;
  width: 100%;
  max-width: 120ch;
  padding: 1rem 0;
  background-color: var(--black);
  color: var(--white);
  margin-bottom: 1rem;
  box-shadow: 10px 10px 2px rgba(0, 0, 0, 0.1);
  font-size: 1.25rem;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 0.33rem;
  text-transform: uppercase;
}
