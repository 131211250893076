.textOverflow {
  /* height: calc(2rem + 2rem + 4 * (1.2rem + 1rem)); */
  /* Padding top, bottom, and 4 * (line height + top/bottom padding) */
  overflow: hidden;
  filter: blur(3px);
  cursor: pointer;
  position: relative;
}

.textOverflow.focused {
  filter: none;
  cursor: initial;
}

.textShift {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  transition: height 0.25s linear 0s;
  overflow: hidden;
}

.instructions {
  padding-top: 1.5rem;
}
